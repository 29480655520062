<template>
  <div class="_main_wrapper relative flex font-poppins">
    <landing-left class="i-left-panel shadow-one" :parent="'list'" />

    <div class="i-right-panel">
      <div class="ws-heading-box relative flex flex-col pb-0">
        <workspace-menu />
      </div>

      <div class="relative">
        <div class="flex items-center mt-2 i-rp-layer-1">
          <div class="h-10 relative i-border-1 border-secondary-two rounded ws-search-width mr-5">
            <input
                type="text"
                class="focus:outline-none py-2 font-poppins ws-search-box bg-transparent text-secondary-one placeholder-secondary-two pl-8"
                :placeholder="searchField.placeholder"
                v-model="searchField.text"
                @click="searchField.placeholder = ''"
                v-click-outside="displaySearchPlaceholder"
            />

            <div class="mr-2 flex items-center justify-between absolute inset-y-0 left-2 pointer-events-none pr-1">
              <img
                  class="w-4 h-4 opacity-70"
                  src="/images/icons/search_icon.svg"
              />
            </div>
          </div>
          <button
              @click="createSurvey()"
              class="h-10 focus:outline-none hover:bg-secondary-five text-primary-one fs-14 font-poppins fw-600 bg-primary-three shadow-one rounded px-6 py-2 border border-primary-four"
          >
            Create New
          </button>
        </div>

        <div class="pl-10 mt-4" style="padding-right: 89px">
          <DataTable
              :options="options"
              :items="filteredList"
              :headers="headers"
          >
            <template #row="{item}">
              <td class="text-left font-poppins fs-14 text-primary-one i-pl-25 fw-600 tblc1 py-6" @click="gotoResponse(item.id)" :class="{'pointer-events-none': activeListIndex !== 0}">
                {{ item.title }}
              </td>
              <td class="text-left font-poppins fs-14 text-secondary-six i-pl-25 tblc2">{{ $lodash.format(item.created_at, 'dd/MM/yyyy') }}</td>
              <td class="text-left font-poppins fs-14 text-secondary-six i-pl-25 tblc5">
                <span v-show="activeListIndex === 1">{{ getUserName(item.created_by) }}</span>
              </td>
              <td class="text-left font-poppins fs-14 text-secondary-six i-pl-25 tblc3">{{ item.updated_at ? $lodash.format(item.updated_at, 'dd/MM/yyyy') : $lodash.format(item.created_at, 'dd/MM/yyyy') }}</td>
              <td
                  class="text-left font-poppins fs-14 i-pl-25 tblc4"
              >
                <span :style="{'color': item.responses_count > 0 ? '#2B80AD' : '#8D8D8D'}">{{ item.responses_count > 0 ? 'Active' : 'Inactive' }}</span>
              </td>
              <td
                  class="text-left font-poppins fs-14 i-pl-25 tblc4"
              >
                <span>{{ item.responses_count }}</span>
              </td>
              <td class="text-left font-poppins fs-14 flex items-center relative i-pl-25 py-6 gap-3">
                <img src="http://clipground.com/images/copy-4.png"
                     class="w-5 h-5 opacity-75 cursor-pointer"
                     title="Click to Copy"
                     @click="copyPreviewLink(item.id)" />
                <router-link title="Edit" :to="{name: 'survey.edit', params: {survey_id: item.id}}" :class="{'pointer-events-none': activeListIndex !== 0}">
                  <EditIcon class="w-5 h-5 opacity-75" />
                </router-link>
                <img
                    class="cursor-pointer w-5 h-5 opacity-75 hover:bg-secondary-three"
                    src="@/assets/images/icons/assessment/share_outline.svg"
                    alt="icon"
                    title="Share"
                    @click="toggleShareModal(true, item)">
                <img
                    class="cursor-pointer w-5 h-5 opacity-75 hover:bg-secondary-three"
                    src="@/assets/images/icons/assessment/trash.svg"
                    alt="icon"
                    title="Delete"
                    @click="deleteSurvey(item)">
              </td>
            </template>
          </DataTable>
        </div>
      </div>
    </div>
    <share-modal :dModal="shareModal" :activeSurvey="activeSurvey" @toggleShareModal="toggleShareModal" />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import LandingLeft from "../../../components/survey/LandingLeft";
import ShareModal from "../../../components/survey/ShareModal";
import DataTable from '@/elements/table/DataTable.vue'
export default {
  name: "Index",
  components: {ShareModal, LandingLeft, DataTable},
  created() {
    document.title = "Survey";
    this.$Progress.start();
    this.$store.state.survey.survey = {}
    this.$store.dispatch("survey/getSurveys")
        .then(response => {
          this.$Progress.finish();
        });
  },
  data() {
    return {
      search: '',
      activeSurvey: null,
      shareModal: false,
      searchField: {
        placeholder: 'Search',
        text: ''
      },
      showMoreOpt: null,
      headers: [
        {
          visible: true,
          text: 'Survey name',
          value: 'name'
        },{
          visible: true,
          text: 'Created on',
          value: 'created_at'
        },{
          visible: true,
          text: 'Created by',
          value: 'created_by'
        },{
          visible: true,
          text: 'Last edited',
          value: 'updated_at'
        },{
          visible: true,
          text: 'Status',
          value: 'status'
        },{
          visible: true,
          text: 'Response',
          value: 'response'
        },{
          visible: true,
          text: 'Action',
          value: 'action'
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      surveys: "survey/surveys",
      activeListIndex: "survey/activeListIndex",
    }),
    filteredList() {
      let data = []
      if (this.activeListIndex == 0) {
        if (this.surveys.own) {
          data = this.surveys.own.filter(el => el.deleted_at == null);
        }
      }
      else if (this.activeListIndex == 1) {
        if (this.surveys.invited) {
          data = this.surveys.invited;
        }
      }
      else if (this.activeListIndex == 2) {
        if (this.surveys.own) {
          data = this.surveys.own.filter(el => el.deleted_at != null);
        }
      }

      if(this.searchField.text) {
        return data.filter(post => {
          return post.title.toLowerCase().includes(this.searchField.text.toLowerCase())
        })
      }
      return data;
    }
  },
  methods: {
    getUserName(user) {
      let title = '';
      if(user.first_name) {
        title = user.first_name;
        if(user.last_name) {
          title = title + ' ' + user.last_name;
        }
      } else {
        title = user.email;
      }
      return title;
    },
    displaySearchPlaceholder() {
      this.searchField = {
        placeholder: 'Search',
        text: ''
      }
    },
    createSurvey() {
      this.$Progress.start();
      this.$store.dispatch("survey/storeSurvey")
      .then(response => {
        if(response && response.data && response.data.status) {
          this.$router.push({name: 'survey.edit', params: {survey_id: response.data.data.id}});
        }
        this.$Progress.finish();
      });
    },
    deleteSurvey(item) {
      this.$Progress.start();
      this.$store.dispatch("survey/deleteSurvey", item.id).then(response => {
        this.$Progress.finish();
      });
    },
    toggleShareModal(status, item) {
      this.shareModal = status;
      this.activeSurvey = item;
    },
    getDisability(item) {
      return !!item.deleted_at;
    },
    gotoResponse(id) {
      this.$store.state.survey.activeSurvey = id;
      this.$router.push({name: 'survey.responses'});
    },
    copyPreviewLink(id) {
      let url = window.location.origin + `/survey/page/${id}`;
      navigator.clipboard.writeText(url);
      alert('Link copied to clipboard. Paste anywhere to use it.');
    }
  },
  setup() {
    const options = {
      hasCheckbox: false
    }

    return {
      options
    }
  }
}
</script>

<style scoped>
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #787878;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #787878;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #787878;
}
</style>
