<template>
  <modal name="survey-share-modal" @closed="closeModal()" :height="'auto'">
    <div class="p-10">
      <div class="flex justify-between i-border-b-1 border-secondary-five">
        <div class="w-33 cursor-pointer">
          <h3 class="font-roboto active-text fs-26 lh-20 share-tab-title">Responders </h3>
        </div>
      </div>
      <div class="modal-body-card overflow-y-auto thin-scrollbar" v-if="activeSurvey">
        <table class="table-auto" style="width: 100%">
          <tbody>
          <tr v-for="(item, index) in getResponders" :key="index" class="share-item">
            <td class="text-left uppercase grey-text fs-14">
              <div class="flex items-center">
                <img :src="getUserImage(item)" alt=""
                     style="width: 30px;height: 30px;"
                     class="i-user-avatar rounded-full cursor-pointer border-2 border-white mr-2">
                <span>{{ item.responder_id ? getUserName(item) : item.email }}</span>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="modal-action-button flex justify-end m-t-25px">
          <button class="fs-14 lh-17px font-roboto text-white fw-bold uppercase instrat-outline-btn modal-create-btn instrat-text-color"
                  @click="closeModal()">Close</button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import ProfileImage from "@/assets/images/icons/profile/profile.png";
export default {
  name: "ShareModal",
  props: ['dModal', 'activeSurvey'],
  data() {
    return {
      image_path: ProfileImage
    }
  },
  computed: {
    getResponders() {
      return this.activeSurvey.responders;
    }
  },
  watch: {
    dModal: function (val) {
      if (val) {
        this.$modal.show('survey-share-modal');
      }
    }
  },
  methods: {
    closeModal() {
      this.$modal.hide('survey-share-modal');
      this.$emit("toggleShareModal", false, null);
    },
    getUserName(user) {
      let title = '';
      user = user.responder;
      if(user && user.first_name) {
        title = user.first_name;
        if(user.last_name) {
          title = title + ' ' + user.last_name;
        }
      } else {
        title = user.email;
      }
      return title;
    },
    getUserImage(user) {
      let image = this.image_path;
      user = user.responder;
      if(user && user.file && user.file.file_path) {
        image = user.file.file_path;
      }
      return image;
    }
  }
}
</script>

<style scoped>
.share-modal-title-card {
  border-bottom: 1px solid #414146;
}

.share-tab-title {
  padding-bottom: 20px;
}

.active-border {
  border-bottom: 1px solid #ffffff;
}

.w-33 {
  width: 33.33%;
}

.modal-body-card {
  padding-top: 20px;
  max-height: 550px;
}

table th, table td {
  padding: 15px 0;
}
.accepted {
  color: #2A93C0;
}
.share-item:hover {
  opacity: 0.5;
}
</style>
